<div id="glam-about-us">
    <main>
        <section id="primary-section">
            <img class="banner-primary" [src]="src">

            <div class="description">
                <p>
                  A Glam Beauty nasceu para oferecer uma solução completa de rotina de beleza. Queremos transformar sua rotina de cuidados oferecendo produtos de alta qualidade a preços acessíveis.
                </p>
                <p>
                  Pode comparar com a concorrência, nossas fórmulas foram pensadas com ativos tecnológicos de primeira linha, aplicados em texturas que deixam a rotina de autocuidado prazerosa e efetiva.
                </p>
            </div>
        </section>

        <section id="second-section">
            <div class="wrapper">
                <div class="wrapper wrapper__image-block">
                    <img
                        src="assets/img/autocuidado-acessivel.webp"
                        alt="Limpeza"
                        loading="lazy"
                        class="wrapper__image-block--self-care"
                    >
                    <div class="wrapper__blur wrapper__blur--self-care"></div>
                </div>

                <div class="wrapper__info">
                    <h2>Autocuidado acessível</h2>
                    <p>
                      Você não precisa de um grande investimento para comprar produtos de qualidade. Nossos preços de assinante garantem que você terá acesso a uma rotina completa de cuidados por um preço super competitivo. Se você conhece algum produto incrível que não pode viver sem, não hesite em entrar em contato conosco, que vamos tentar desenvolver uma versão melhor e mais acessível.
                    </p>
                    <a
                        href="https://glam.com.br/loja/collections/glambeauty"
                        target="_blank"
                    >
                        <button class="btn-purple-round">saber mais</button>
                    </a>
                </div>
            </div>

            <div class="wrapper">
                <div class="wrapper__image-block">
                    <img
                        src="assets/img/alta-performance.webp"
                        alt="Hidratação"
                        loading="lazy"
                        class="wrapper__image-block--high-performance"
                    >
                    <div class="wrapper__blur wrapper__blur--high-performance"></div>
                </div>

                <div class="wrapper__info">
                    <h2>Produtos de alta performance</h2>
                    <p>
                      Cada produto foi pensado e comparado com as melhores opções no mercado, nosso expertise da Glam coleta constantemente o feedback de uso dos produtos concorrentes e nos permite desenvolver produtos comprovados com alta performance. Para desenvolver produtos mais assertivos, utilizamos dados das características físicas e preferências de consumo dos nossos clientes.
                    </p>
                    <a
                        href="https://glam.com.br/loja/collections/glambeauty"
                        target="_blank"
                    >
                        <button class="btn-purple-round">conhecer a linha</button>
                    </a>
                </div>
            </div>

            <div class="wrapper">
                <div class="wrapper__image-block">
                    <img
                        src="assets/img/rotina-descomplicada.webp"
                        alt="Hidratação"
                        loading="lazy"
                        class="wrapper__image-block--routine"
                    >
                    <div class="wrapper__blur wrapper__blur--routine"></div>
                </div>

                <div class="wrapper__info">
                    <h2>Rotina descomplicada</h2>
                    <p>
                      A Glam Beauty começou sua jornada oferecendo produtos de cuidados faciais, mas aos poucos fomos incorporando produtos de categorias como Higiene e Cuidados com o Corpo, Perfumaria e Maquiagem. A nossa jornada continua e seguimos nos desafiando para trazer produtos essenciais no dia a dia das nossas consumidoras.
                    </p>
                    <a
                        href="https://glam.com.br/loja/collections/glambeauty"
                        target="_blank"
                    >
                        <button class="btn-purple-round">montar rotina</button>
                    </a>
                </div>
            </div>

            <div class="wrapper">
                <div class="wrapper__image-block">
                    <img
                        src="assets/img/tecnologia-ponta.webp"
                        alt="Hidratação"
                        loading="lazy"
                        class="wrapper__image-block--high-performance"
                    >
                    <div class="wrapper__blur wrapper__blur--protection"></div>
                </div>

                <div class="wrapper__info">
                    <h2>Tecnologia de ponta</h2>
                    <p>
                      A Glam Beauty é a primeira marca do Brasil a nascer a partir de um Clube de Beleza e, por isso, já possui a tecnologia necessária para oferecer planos de assinatura e ofertas inteligentes. Através da combinação entre análise de dados e machine learning, criamos um portfólio sob medida, com os produtos mais procurados pelo público.
                    </p>
                </div>
            </div>
        </section>

        <section
            id="third-section"
            *ngIf="!isProduction"
        >
            <!-- <mp-instagram [innerWidth]="this.innerWidth"
        [rounded]="true"
        username="useglam"
        token="IGQVJVY0p5MXhEcGVteElmeXNlUERBcWdHVUEtczhCeFlKa1k3TUlfcTJZAOWYzajdyTl92LS1iSExEb1dZAOWJULVN1d0EyVjViV2pFQW9aZAzRxQ2N2ak1STTRyZA1JRWDRzNVAzWl9sY1VXZAVVnczJwMAZDZD">
      </mp-instagram> -->
            <glam-instagram-carousel [innerWidth]="innerWidth"></glam-instagram-carousel>
        </section>

        <section
            id="fourth-section"
            *ngIf="!isProduction"
        >
            <glam-newsletter></glam-newsletter>
        </section>
    </main>
</div>
